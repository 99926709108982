import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent, Grid, TextField, Zoom, IconButton } from '@material-ui/core';
import NumberFormat from 'react-number-format';

import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    multilineColor: {
        color: theme.palette.secondary.main
    },
    marginError: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: 'unset !important'
        }
    }
}));

function Cuenta(props) {
    const { theme, datos, setShowBackdrop, setMessage, setSeverity, setSnack, getAccessTokenWithRefresh, API_DEFAULT, obtenerPerfil } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const classes = useStyles();
    const [edit, setEdit] = useState(false);
    const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [correo, setCorreo] = useState('');
    const [celular, setCelular] = useState('');

    const [errores, setErrores] = useState(Array(4).fill(false));

    const getFromDatos = () => {
        setNombre(datos['first_name'] === '' ? 'Sin asignar' : datos['first_name']);
        setApellidos(datos['last_name'] === '' ? 'Sin asignar' : datos['last_name']);
        setCorreo(datos['email'] === '' ? 'Sin asignar' : datos['email']);
        setCelular(datos['celular'] === '' ? 'Sin asignar' : datos['celular']);
    }

    const validarDatos = () => {
        let errorDatos = false;
        if (nombre === '') {
            errorDatos = true;
            errores[0] = true;
        }
        if (apellidos === '') {
            errorDatos = true;
            errores[1] = true;
        }
        if (/^\S+@\S+\.\S+$/.test(correo) === false && correo !== '') {
            errorDatos = true;
            errores[2] = true;
        }
        if (/^3[0-9]{9}/.test(celular) === false && celular !== '') {
            errorDatos = true;
            errores[3] = true;
        }
        if (errorDatos) {
            setErrores([...errores]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            setEdit(false);
            putDatos();
        }
    }

    const putDatos = async (access = accessToken) => {
        setShowBackdrop(true);

        const res = await fetch(`${API_DEFAULT}/usuarios/perfil/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'first_name': nombre,
                'last_name': apellidos,
                'email': correo,
                'celular': celular
            })
        });

        if (res.ok) {
            obtenerPerfil(access);
            setMessage('Se ha actualizado exitosamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        putDatos(newAccess);
                    }
                }
                else {
                    setShowBackdrop(false);
                    setMessage('Ocurrio un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    const clearErrores = () => {
        setErrores(Array(4).fill(false));
    }

    useEffect(() => {
        getFromDatos();
    }, [datos]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (edit) {
            setNombre(datos['first_name']);
            setApellidos(datos['last_name']);
            setCorreo(datos['email']);
            setCelular(datos['celular']);
        }
    }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={{ overflow: 'hidden' }}>
            <CardContent style={{ paddingBottom: 0 }}>
                <Grid container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item sm={6} xs={12}>
                        <TextField
                            className={classes.marginError}
                            variant={edit ? 'filled' : 'standard'}
                            size={edit ? 'small' : 'medium'}
                            label='Nombre'
                            value={nombre}
                            fullWidth
                            onChange={(e) => { let value = e.target.value; if (value.length > 0) { if (value.includes(' ')) { let values = e.target.value.split(' '); value = ''; for (let i = 0; i < values.length; i++) { value = values[i] === '' ? value + ' ' : value + values[i][0].toUpperCase() + values[i].slice(1).toLowerCase() + ' ' } value = value.slice(0, -1) } else { value = e.target.value[0].toUpperCase() + e.target.value.slice(1).toLowerCase() } } setNombre(value.replace(/[^A-Za-zÀ-ú\u00f1\u00d1 ]/g, '')); errores[0] = false; setErrores([...errores]) }}
                            InputProps={{
                                readOnly: !edit,
                                className: (!edit && nombre === 'Sin asignar') ? classes.multilineColor : null
                            }}
                            error={errores[0]}
                            helperText={errores[0] ? 'Este campo no puede quedar vacío' : null}
                            style={{ marginBottom: errores[1] && !errores[0] ? 23.5 : null }}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            className={classes.marginError}
                            variant={edit ? 'filled' : 'standard'}
                            size={edit ? 'small' : 'medium'}
                            label='Apellidos'
                            value={apellidos}
                            fullWidth
                            onChange={(e) => { let value = e.target.value; if (value.length > 0) { if (value.includes(' ')) { let values = e.target.value.split(' '); value = ''; for (let i = 0; i < values.length; i++) { value = values[i] === '' ? value + ' ' : value + values[i][0].toUpperCase() + values[i].slice(1).toLowerCase() + ' ' } value = value.slice(0, -1) } else { value = e.target.value[0].toUpperCase() + e.target.value.slice(1).toLowerCase() } } setApellidos(value.replace(/[^A-Za-zÀ-ú\u00f1\u00d1 ]/g, '')); errores[1] = false; setErrores([...errores]) }}
                            InputProps={{
                                readOnly: !edit,
                                className: (!edit && apellidos === 'Sin asignar') ? classes.multilineColor : null
                            }}
                            error={errores[1]}
                            helperText={errores[1] ? 'Este campo no puede quedar vacío' : null}
                            style={{ marginBottom: errores[0] && !errores[1] ? 23.5 : null }}
                        />
                    </Grid>
                </Grid>
                <TextField
                    style={{ marginTop: theme.spacing(2) }}
                    variant={edit ? 'filled' : 'standard'}
                    size={edit ? 'small' : 'medium'}
                    label='Correo'
                    value={correo}
                    fullWidth
                    onChange={(e) => { setCorreo(e.target.value); errores[2] = false; setErrores([...errores]) }}
                    InputProps={{
                        readOnly: !edit,
                        className: (!edit && correo === 'Sin asignar') ? classes.multilineColor : null
                    }}
                    error={errores[2]}
                    helperText={errores[2] ? 'Ingrese un correo válido' : null}
                />
                {!edit && (celular === 'Sin asignar' || celular === '') ?
                    <TextField
                        style={{ marginTop: theme.spacing(2) }}
                        variant='standard'
                        size='medium'
                        label='Celular'
                        value={celular}
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            className: (celular === 'Sin asignar') ? classes.multilineColor : null
                        }}
                    />
                    :
                    <NumberFormat
                        customInput={TextField}
                        format='+57 ### #######'
                        mask='_'
                        style={{ marginTop: theme.spacing(2) }}
                        variant={edit ? 'filled' : 'standard'}
                        size={edit ? 'small' : 'medium'}
                        label='Celular'
                        value={celular}
                        fullWidth
                        onValueChange={(e) => { setCelular(e.value); errores[3] = false; setErrores([...errores]) }}
                        InputProps={{
                            readOnly: !edit
                        }}
                        error={errores[3]}
                        helperText={errores[3] ? 'Ingrese un celular válido' : null}
                    />
                }
                <TextField
                    style={{ marginTop: theme.spacing(2) }}
                    label='Roles'
                    value={datos['roles']}
                    fullWidth
                    InputProps={{ readOnly: true }}
                />
            </CardContent>
            <div style={{ margin: theme.spacing(2), display: 'flex', justifyContent: 'flex-end', height: 40, position: 'relative' }}>
                <Zoom
                    in={(edit ? 1 : 0) === 0}
                    timeout={transitionDuration}
                    style={{
                        transitionDelay: `${(edit ? 1 : 0) === 0 ? transitionDuration.exit : 0}ms`,
                    }}
                    unmountOnExit
                >
                    <IconButton style={{ position: 'absolute', height: 40, width: 40 }} color='secondary' onClick={() => setEdit(true)}>
                        <EditIcon />
                    </IconButton>
                </Zoom>
                <Zoom
                    in={(edit ? 1 : 0) === 1}
                    timeout={transitionDuration}
                    style={{
                        transitionDelay: `${(edit ? 1 : 0) === 1 ? transitionDuration.exit : 0}ms`,
                    }}
                    unmountOnExit
                >
                    <div style={{ position: 'absolute' }}>
                        <IconButton style={{ marginRight: theme.spacing(1), height: 40, width: 40 }} color='primary' onClick={() => validarDatos()}>
                            <DoneIcon />
                        </IconButton>
                        <IconButton style={{ height: 40, width: 40 }} color='secondary' onClick={() => { clearErrores(); setEdit(false); getFromDatos() }}>
                            <ClearIcon />
                        </IconButton>
                    </div>
                </Zoom>
            </div>
        </div>
    );
}

export default Cuenta;