import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';
import { Card, CardContent, Grid, Typography, Tab, Tabs, Paper, Collapse, Avatar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import Compress from 'compress.js';

import CameraAltIcon from '@material-ui/icons/CameraAlt';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';

import Cuenta from './Cuenta';
import Seguridad from './Seguridad';

const useStyles = makeStyles((theme) => ({
    profileImage: {
        width: 180,
        height: 180,
        margin: '0 auto 10px',
        borderRadius: '50%',
        boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)'
    },
    tooltipPlacementBottom: {
        margin: 8,
    },
    multilineColor: {
        color: theme.palette.secondary.main
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    input: {
        display: 'none',
    },
    speedDial: {
        position: 'absolute',
        '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
            bottom: 90 - 82,
            right: 14 - 24,
        },
        [theme.breakpoints.up('md')]: {
            '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
                bottom: 90,
                right: 14,
            },
        }
    },
    cardPadding: {
        padding: 0,
        paddingTop: 8,
        [theme.breakpoints.up('sm')]: {
            padding: 8
        },
        [theme.breakpoints.up('md')]: {
            padding: 12,
            paddingLeft: 0
        }
    }
}));


function Perfil(props) {
    const { theme, setShowBackdrop, API_DEFAULT, getAccessTokenWithRefresh, validRole, setMessage, setSeverity, setSnack, userImage, getRole, logIn, setUsername } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const classes = useStyles();
    const hiddenInput = useRef(null);
    const [value, setValue] = useState(0);
    const [datos, setDatos] = useState({});

    const [open, setOpen] = useState(false);

    const actions = userImage !== '' ?
        [
            { icon: <CameraAltIcon />, name: 'Subir imagen' },
            { icon: <ClearIcon />, name: 'Eliminar' }
        ]
        :
        [
            { icon: <CameraAltIcon />, name: 'Subir imagen' }
        ];

    const obtenerPerfil = async (access = accessToken) => {
        setShowBackdrop(true);

        const res = await fetch(`${API_DEFAULT}/usuarios/perfil/`, {
            headers: { 'Authorization': `Bearer ${access}` },
        });

        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    obtenerPerfil(newAccess);
                }
            }
            else {
                setDatos(res);
                setUsername(res['username']);
                setShowBackdrop(false);
            }
        });
    }

    const subirImagen = async (access = accessToken, archivo) => {
        setShowBackdrop(true);

        const compress = new Compress();
        const resizedImage = await compress.compress([archivo], {
            size: 2, // the max size in MB, defaults to 2MB
            quality: 1, // the quality of the image, max is 1,
            maxWidth: 300, // the max width of the output image, defaults to 1920px
            maxHeight: 300, // the max height of the output image, defaults to 1920px
            resize: true // defaults to true, set false if you do not want to resize the image width and height
        })

        const newFile = Compress.convertBase64ToFile(resizedImage[0].data, resizedImage[0].ext)

        let formData = new FormData();
        formData.append('imagen', newFile, archivo.name);

        const res = await fetch(`${API_DEFAULT}/usuarios/subir_imagen/`, {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${access}` },
            body: formData
        });

        if (res.ok) {
            getRole();
            setMessage('La imagen se ha cambiado exitosamente');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        subirImagen(newAccess, archivo);
                    }
                }
                else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    const eliminarImagen = async (access = accessToken) => {
        setShowBackdrop(true);

        const res = await fetch(`${API_DEFAULT}/usuarios/subir_imagen/`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${access}` }
        });

        if (res.ok) {
            getRole();
            setMessage('La imagen se ha eliminado exitosamente');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        eliminarImagen(newAccess);
                    }
                }
                else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    useEffect(() => { if (validRole) { obtenerPerfil() } }, [validRole]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card>
            <CardContent>
                <Collapse in={datos['username']} mountOnEnter unmountOnExit>
                    <Grid container
                        direction='row'
                        spacing={2}
                    >
                        <Grid item md={'auto'} xs={12}>
                            <div className={classes.sectionDesktop}>
                                <div style={{ textAlign: 'center', padding: 24, position: 'relative' }}>
                                    <Avatar className={classes.profileImage} src={userImage} />
                                    <SpeedDial
                                        ariaLabel='SpeedDial image'
                                        FabProps={{ size: 'small', color: 'secondary' }}
                                        className={classes.speedDial}
                                        icon={<EditIcon />}
                                        onClose={() => setOpen(false)}
                                        onOpen={() => setOpen(true)}
                                        open={open}
                                        direction='up'
                                    >
                                        {actions.map((action) => (
                                            <SpeedDialAction
                                                key={action.name}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                                onClick={() => { if (action.name === 'Eliminar') { eliminarImagen() } else { hiddenInput.current.click() } setOpen(false) }}
                                            />
                                        ))}
                                    </SpeedDial>
                                    <Typography style={{ fontSize: 20, fontWeight: 500 }}>{`${datos['username']}`}</Typography>
                                    <Typography style={{ fontSize: 15, marginTop: -theme.spacing(0.6) }}>{`@${datos['user']}`}</Typography>
                                </div>
                            </div>
                            <div className={classes.sectionMobile}>
                                <div style={{ textAlign: 'center', padding: 24, paddingBottom: 4 }}>
                                    <div style={{ position: 'relative', width: 180, margin: 'auto' }}>
                                        <Avatar className={classes.profileImage} src={userImage} />
                                        <SpeedDial
                                            ariaLabel='SpeedDial image'
                                            FabProps={{ size: 'small', color: 'secondary' }}
                                            className={classes.speedDial}
                                            icon={<EditIcon />}
                                            onClose={() => setOpen(false)}
                                            onOpen={() => setOpen(true)}
                                            open={open}
                                            direction='up'
                                        >
                                            {actions.map((action) => (
                                                <SpeedDialAction
                                                    key={action.name}
                                                    icon={action.icon}
                                                    tooltipTitle={action.name}
                                                    onClick={() => { if (action.name === 'Eliminar') { eliminarImagen() } else { hiddenInput.current.click() } setOpen(false) }}
                                                />
                                            ))}
                                        </SpeedDial>
                                    </div>
                                    <Typography style={{ fontSize: 20, fontWeight: 500 }}>{`${datos['username']}`}</Typography>
                                    <Typography style={{ fontSize: 15, marginTop: -theme.spacing(0.6) }}>{`@${datos['user']}`}</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item md xs>
                            <div className={classes.cardPadding}>
                                <Card>
                                    <Paper square elevation={4} style={{ backgroundColor: theme.palette.primary.main }}>
                                        <Tabs fullWidth value={value} onChange={(event, newValue) => { setValue(newValue) }}>
                                            <Tab style={{ textTransform: 'none', fontSize: 16, color: 'white', fontWeight: 400 }} label='Cuenta' />
                                            <Tab style={{ textTransform: 'none', fontSize: 16, color: 'white', fontWeight: 400 }} label='Seguridad' />
                                        </Tabs>
                                    </Paper>
                                    <SwipeableViews
                                        index={value}
                                        onChangeIndex={(index) => { setValue(index) }}
                                        containerStyle={{
                                            transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s'
                                        }}
                                    >
                                        <Cuenta
                                            theme={theme}
                                            datos={datos}
                                            accessToken={[accessToken, setAccessToken]}
                                            getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                            setShowBackdrop={setShowBackdrop}
                                            setMessage={setMessage}
                                            setSeverity={setSeverity}
                                            setSnack={setSnack}
                                            API_DEFAULT={API_DEFAULT}
                                            obtenerPerfil={obtenerPerfil}
                                        />
                                        <Seguridad
                                            theme={theme}
                                            setMessage={setMessage}
                                            setSeverity={setSeverity}
                                            setSnack={setSnack}
                                            accessToken={[accessToken, setAccessToken]}
                                            getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                            setShowBackdrop={setShowBackdrop}
                                            API_DEFAULT={API_DEFAULT}
                                            logIn={logIn}
                                            username={datos['user']}
                                        />
                                    </SwipeableViews>
                                </Card>
                            </div>
                        </Grid>
                    </Grid>
                    <input ref={hiddenInput} accept='image/*' className={classes.input} id='icon-button-file' type='file' onChange={(event) => { setOpen(false); subirImagen(accessToken, event.target.files[0]); event.target.value = null }} />
                </Collapse>
            </CardContent>
        </Card >
    );
}

export default Perfil;