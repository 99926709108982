import React, { useState, useRef, useEffect } from 'react';
import { Card, CardHeader, CardContent, Divider, Typography, Button, IconButton, Collapse, TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles({
    cardTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
});

function Seguridad(props) {
    const { theme, setMessage, setSeverity, setSnack, setShowBackdrop, API_DEFAULT, getAccessTokenWithRefresh, logIn, username } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const classes = useStyles();
    const [cambiar, setCambiar] = useState(false);
    const [cerrar, setCerrar] = useState(false);

    const [contrAnterior, setContrAnterior] = useState('');
    const [contrNueva, setContrNueva] = useState('');
    const [contrNueva2, setContrNueva2] = useState('');
    const [erroresCambio, setErroresCambio] = useState(Array(3).fill(false));

    const [isClick, setIsClick] = useState(Array(3).fill(false));
    const [cursorPosition, setCursorPosition] = useState(Array(3).fill(0));
    const [showPassword, setShowPassword] = useState(Array(3).fill(false));
    const inputRef = [useRef(null), useRef(null), useRef(null)];

    const [contra, setContra] = useState('');
    const [errorCerrar, setErrorCerrar] = useState(false);

    const inputRef2 = useRef(null);
    const [isClick2, setIsClick2] = useState(false);
    const [cursorPosition2, setCursorPosition2] = useState(0);
    const [showPassword2, setShowPassword2] = useState(false);


    const validarCambio = () => {
        let errorCambio = false;
        if (contrAnterior === '') {
            errorCambio = true;
            erroresCambio[0] = true;
        }
        if (contrNueva === '' || contrAnterior === contrNueva || /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*-])(?=.{8,})/.test(contrNueva) === false || (contrNueva !== contrNueva2)) {
            errorCambio = true;
            erroresCambio[1] = true;
        }
        if (contrNueva2 === '' || (contrNueva2 !== contrNueva)) {
            errorCambio = true;
            erroresCambio[2] = true;
        }
        if (errorCambio) {
            setErroresCambio([...erroresCambio]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            cambiarContrasenia();
        }
    }

    const textoRegex = `La contraseña debe contener:${!/^(?=.*[a-z])/.test(contrNueva) ? '\n- Al menos 1 carácter alfabético en minúscula' : ''}${!/^(?=.*[A-Z])/.test(contrNueva) ? '\n- Al menos 1 carácter alfabético en mayúscula' : ''}${!/^(?=.*[0-9])/.test(contrNueva) ? '\n- Al menos 1 carácter numérico' : ''}${!/^(?=.*[#?!@$%^&*-])/.test(contrNueva) ? '\n- Al menos un carácter especial' : ''}${!/^(?=.{8,})/.test(contrNueva) ? '\n- Ocho caracteres o más' : ''}`;

    const validarCerrar = () => {
        if (contra === '') {
            setErrorCerrar(true);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            cerrarSesiones();
        }
    }

    const cambiarContrasenia = async (access = accessToken) => {
        setShowBackdrop(true);

        const res = await fetch(`${API_DEFAULT}/usuarios/cambiar_contrasenia/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'old_password': contrAnterior,
                'new_password': contrNueva
            })
        });

        if (res.ok) {
            logIn(username, contrNueva);
            setCambiar(false);
            setMessage('Se ha cambiado la contraseña exitosamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        cambiarContrasenia(newAccess);
                    }
                }
                else if (res['old_password'][0] === 'Wrong password.') {
                    erroresCambio[0] = true;
                    setErroresCambio([...erroresCambio]);
                    setShowBackdrop(false);
                    setMessage('La contraseña actual ingresada es incorrecta.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
                else {
                    setShowBackdrop(false);
                    setMessage('Ocurrio un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    const cerrarSesiones = async (access = accessToken) => {
        setShowBackdrop(true);

        const res2 = await fetch(`${API_DEFAULT}/usuarios/validar_contrasenia/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'password': contra
            })
        });

        if (res2.ok) {
            const res = await fetch(`${API_DEFAULT}/usuarios/logout/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
                body: JSON.stringify({
                    'all': true
                })
            });

            if (res.ok) {
                logIn(username, contra);
                setCerrar(false);
                setMessage('Se han cerrado todas las sesiones abiertas.');
                setSeverity('success');
                setTimeout(() => { setSnack(true) }, 0);
            }
            else {
                setShowBackdrop(false);
                setMessage('Ocurrio un error, intente de nuevo.');
                setSeverity('error');
                setTimeout(() => { setSnack(true) }, 0);
            }
        }
        else {
            res2.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        cerrarSesiones(newAccess);
                    }
                }
                else {
                    setErrorCerrar(true);
                    setShowBackdrop(false);
                    setMessage('La contraseña ingresada es incorrecta.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    const clearCambiar = () => {
        setContrAnterior('');
        setContrNueva('');
        setContrNueva2('');
        setErroresCambio(Array(3).fill(false));
        setIsClick(Array(3).fill(false));
        setCursorPosition(Array(3).fill(0));
        setShowPassword(Array(3).fill(false));
    }

    const clearCerrar = () => {
        setContra('');
        setErrorCerrar(false);
        setIsClick2(false);
        setCursorPosition2(0);
        setShowPassword2(false);
    }

    useEffect(() => {
        if (cambiar) {
            clearCambiar();
        }
    }, [cambiar]);

    useEffect(() => {
        if (cerrar) {
            clearCerrar();
        }
    }, [cerrar]);

    return (
        <div style={{ overflow: 'hidden' }}>
            <CardContent>
                <Card variant='outlined'>
                    <CardHeader
                        component={Button}
                        style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1), textTransform: 'none', borderRadius: 'unset' }}
                        classes={{
                            title: classes.cardTitle
                        }}
                        title={
                            <React.Fragment>
                                <Typography style={{ fontWeight: 500, fontSize: 18 }}>Cambiar contraseña</Typography>
                                <IconButton
                                    style={{ height: 32, width: 32, pointerEvents: 'none' }}
                                    color='primary'
                                >
                                    {cambiar ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </React.Fragment>
                        }
                        onClick={() => setCambiar(!cambiar)}
                        color='primary'
                        fullWidth
                    />
                    <Collapse in={cambiar} mountOnEnter unmountOnExit>
                        <Divider style={{ height: 0.99 }} />
                        <CardContent>
                            <TextField
                                inputRef={inputRef[0]}
                                type={showPassword[0] ? 'text' : 'password'}
                                variant='filled'
                                size='small'
                                label='Contraseña actual'
                                value={contrAnterior}
                                onSelect={() => { if (isClick[0]) { inputRef[0].current.selectionStart = cursorPosition[0]; isClick[0] = false; setIsClick([...isClick]) } }}
                                onChange={(e) => { setContrAnterior(e.target.value); erroresCambio[0] = false; if (erroresCambio[1] && contrAnterior === contrNueva && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*-])(?=.{8,})/.test(contrNueva)) { erroresCambio[1] = false } setErroresCambio([...erroresCambio]) }}
                                error={erroresCambio[0]}
                                helperText={erroresCambio[0] ? (contrAnterior !== '' ? 'La contraseña actual ingresada es incorrecta' : 'Este campo no puede quedar vacío') : null}
                                InputProps={{
                                    endAdornment: (
                                        < InputAdornment position='end' >
                                            <IconButton
                                                size='small'
                                                onClick={() => { showPassword[0] = !showPassword[0]; setShowPassword([...showPassword]) }}
                                                onMouseDown={(event) => { event.preventDefault(); cursorPosition[0] = inputRef[0].current.selectionStart; setCursorPosition([...cursorPosition]); isClick[0] = document.activeElement === inputRef[0].current; setIsClick([...isClick]) }}
                                            >
                                                {showPassword[0] ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                                required
                            />
                            <TextField
                                style={{ marginTop: theme.spacing(2) }}
                                inputRef={inputRef[1]}
                                type={showPassword[1] ? 'text' : 'password'}
                                variant='filled'
                                size='small'
                                label='Nueva contraseña'
                                value={contrNueva}
                                onSelect={() => { if (isClick[1]) { inputRef[1].current.selectionStart = cursorPosition[1]; isClick[1] = false; setIsClick([...isClick]) } }}
                                onChange={(e) => { setContrNueva(e.target.value); erroresCambio[1] = false; erroresCambio[2] = false; setErroresCambio([...erroresCambio]) }}
                                error={erroresCambio[1]}
                                helperText={erroresCambio[1] ? (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*-])(?=.{8,})/.test(contrNueva) === false ? <span style={{ whiteSpace: 'pre-line' }}>{textoRegex}</span> : ((contrAnterior === contrNueva && contrNueva !== '') ? 'La nueva contraseña debe ser diferente a la actual' : 'Las contraseñas no coinciden')) : (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*-])(?=.{8,})/.test(contrNueva) === false ? <span style={{ whiteSpace: 'pre-line' }}>{textoRegex}</span> : null)}
                                InputProps={{
                                    endAdornment: (
                                        < InputAdornment position='end' >
                                            <IconButton
                                                size='small'
                                                onClick={() => { showPassword[1] = !showPassword[1]; setShowPassword([...showPassword]) }}
                                                onMouseDown={(event) => { event.preventDefault(); cursorPosition[1] = inputRef[1].current.selectionStart; setCursorPosition([...cursorPosition]); isClick[1] = document.activeElement === inputRef[1].current; setIsClick([...isClick]) }}
                                            >
                                                {showPassword[1] ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                                required
                            />
                            <TextField
                                style={{ marginTop: theme.spacing(2) }}
                                inputRef={inputRef[2]}
                                type={showPassword[2] ? 'text' : 'password'}
                                variant='filled'
                                size='small'
                                label='Confirmar nueva contraseña'
                                value={contrNueva2}
                                onSelect={() => { if (isClick[2]) { inputRef[2].current.selectionStart = cursorPosition[2]; isClick[2] = false; setIsClick([...isClick]) } }}
                                onChange={(e) => { setContrNueva2(e.target.value); erroresCambio[2] = false; erroresCambio[1] = false; setErroresCambio([...erroresCambio]) }}
                                error={erroresCambio[2]}
                                helperText={erroresCambio[2] ? (contrNueva2 !== '' ? 'Las contraseñas no coinciden' : 'Este campo no puede quedar vacío') : null}
                                InputProps={{
                                    endAdornment: (
                                        < InputAdornment position='end' >
                                            <IconButton
                                                size='small'
                                                onClick={() => { showPassword[2] = !showPassword[2]; setShowPassword([...showPassword]) }}
                                                onMouseDown={(event) => { event.preventDefault(); cursorPosition[2] = inputRef[2].current.selectionStart; setCursorPosition([...cursorPosition]); isClick[2] = document.activeElement === inputRef[2].current; setIsClick([...isClick]) }}
                                            >
                                                {showPassword[2] ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                                required
                            />
                        </CardContent>
                        <div style={{ marginRight: theme.spacing(2), marginBottom: theme.spacing(2), display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton style={{ marginRight: theme.spacing(1), height: 40, width: 40 }} color='primary' onClick={() => validarCambio()}>
                                <DoneIcon />
                            </IconButton>
                            <IconButton style={{ height: 40, width: 40 }} color='secondary' onClick={() => setCambiar(false)}>
                                <ClearIcon />
                            </IconButton>
                        </div>
                    </Collapse>
                </Card>
                <Card variant='outlined' style={{ marginTop: theme.spacing(1) }}>
                    <CardHeader
                        component={Button}
                        style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1), textTransform: 'none', borderRadius: 'unset' }}
                        classes={{
                            title: classes.cardTitle
                        }}
                        title={
                            <React.Fragment>
                                <Typography style={{ fontWeight: 500, fontSize: 18 }}>Cerrar sesiones abiertas</Typography>
                                <IconButton
                                    style={{ height: 32, width: 32, pointerEvents: 'none' }}
                                    color='primary'
                                >
                                    {cerrar ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </React.Fragment>
                        }
                        onClick={() => setCerrar(!cerrar)}
                        color='primary'
                        fullWidth
                    />
                    <Collapse in={cerrar} mountOnEnter unmountOnExit>
                        <Divider style={{ height: 0.99 }} />
                        <CardContent>
                            <Typography align='justify' style={{ fontSize: 15 }}>Con esta opción podrás cerrar las sesiones que tengas abiertas en los diferentes aplicativos del Sistema CNC, en todos los dispositivos en los que hayas iniciado. Esta opción puede tardar hasta máximo 5 minutos en realizarse, dependiendo de la cantidad de aplicativos y diferentes dispositivos en los que se haya ingresado.</Typography>
                            <Typography align='justify' style={{ fontWeight: 500, marginTop: theme.spacing(1), fontSize: 15 }}>¿Deseas cerrar todas las sesiones abiertas con tu usuario?</Typography>
                            <TextField
                                style={{ marginTop: theme.spacing(2) }}
                                inputRef={inputRef2}
                                type={showPassword2 ? 'text' : 'password'}
                                variant='filled'
                                size='small'
                                label='Contraseña'
                                value={contra}
                                onSelect={() => { if (isClick2) { inputRef2.current.selectionStart = cursorPosition2; setIsClick2(false) } }}
                                onChange={(e) => { setContra(e.target.value); setErrorCerrar(false) }}
                                error={errorCerrar}
                                helperText={errorCerrar ? (contra !== '' ? 'La contraseña ingresada es incorrecta' : 'Este campo no puede quedar vacío') : null}
                                InputProps={{
                                    endAdornment: (
                                        < InputAdornment position='end' >
                                            <IconButton
                                                size='small'
                                                onClick={() => { setShowPassword2(!showPassword2) }}
                                                onMouseDown={(event) => { event.preventDefault(); setCursorPosition2(inputRef2.current.selectionStart); setIsClick2(document.activeElement === inputRef2.current) }}
                                            >
                                                {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                                required
                            />
                        </CardContent>
                        <div style={{ marginRight: theme.spacing(2), marginBottom: theme.spacing(2), display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton style={{ marginRight: theme.spacing(1), height: 40, width: 40 }} color='primary' onClick={() => validarCerrar()}>
                                <DoneIcon />
                            </IconButton>
                            <IconButton style={{ height: 40, width: 40 }} color='secondary' onClick={() => setCerrar(false)}>
                                <ClearIcon />
                            </IconButton>
                        </div>
                    </Collapse>
                </Card>
            </CardContent>
        </div>
    );
}

export default Seguridad;